/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useContext, useMemo, useState } from 'react';
import { SzButton, SzIcon } from 'react-theme-components';
import { BaseStoreContext, LanguageContext } from '../../contexts';
import {
  MovementSerializer,
  ExportSerializationResultInterface,
} from './Export/MovementSerializer';
import { Currency, getDate } from '../../helpers';
import { OptionInterface } from '../Filters';
import { MovementExportChart as MovementExportChartProvider } from '../../interfaces/providers';
import { TypeSelectorContext } from '../TypeSelector';

const XlsxTemplate = require('xlsx-template');

interface ExportOptionsInterface {
  endDate: Date;
  startDate: Date;
}

interface PlaceholderInterface {
  placeholder: string;
  type: string;
  name: string;
  key: string;
  subType: string;
  full: boolean;
}

// https://github.com/optilude/xlsx-template/blob/752568a81d7a635ba7f9a8a7347f5e8a2c7889b3/lib/index.js#L811
// Regex customized to skip character " in first group
//
// Return a list of tokens that may exist in the string.
// Keys are: `placeholder` (the full placeholder, including the `${}`
// delineators), `name` (the name part of the token), `key` (the object key
// for `table` tokens), `full` (boolean indicating whether this placeholder
// is the entirety of the string) and `type` (one of `table` or `cell`)
function extractPlaceholders(string): PlaceholderInterface[] {
  // Yes, that's right. It's a bunch of brackets and question marks and stuff.
  const re = /\${(?:([^"]+?):)?(.+?)(?:\.(.+?))?(?::(.+?))??}/g;

  const matches: PlaceholderInterface[] = [];
  let match: RegExpExecArray | null = null;
  while ((match = re.exec(string)) !== null) {
    matches.push({
      placeholder: match[0],
      type: match[1] || 'normal',
      name: match[2],
      key: match[3],
      subType: match[4],
      full: match[0].length === string.length,
    });
  }

  return matches;
}

export const MovementExportAllChart: React.FC<ExportOptionsInterface> = ({
  startDate,
  endDate,
}) => {
  const {
    itemFiltersSelected: { organization, plant, contract },
  } = useContext(BaseStoreContext);
  const { translate } = useContext(LanguageContext);
  const { selected } = useContext(TypeSelectorContext);
  const [isLoading, setIsLoading] = useState(false);
  const endDeliveryDate = getDate(endDate);
  const startDeliveryDate = getDate(startDate);
  const memoizedFilters = useMemo(
    () => ({
      currencyCode: new Currency().getDecoded()?.value,
      endDeliveryDate,
      idCompanies: plant,
      idOrganizations: organization,
      role: selected,
      isService: Array.isArray(contract)
        ? contract[0]
        : (contract as OptionInterface).value,
      startDeliveryDate,
    }),
    [contract, endDeliveryDate, organization, plant, startDeliveryDate, selected]
  );

  const serializer = new MovementSerializer({ translate });
  const today = new Date();

  const handleClick = () => {
    setIsLoading(true);
    window.dataLayer.push({
      event: 'sz_conversion',
      sz_conversion_type: `Export Mouvements`,
    });

    new MovementExportChartProvider({ filters: memoizedFilters })
      .getChart()
      .then((data) => {
        return serializer.serialize(data);
      })
      .then(
        (
          result: ExportSerializationResultInterface
        ): Promise<{
          result: ExportSerializationResultInterface;
          buffer: ArrayBuffer;
        }> => {
          // fetch right template : movement has saved Co2 column
          return fetch('/templates/export_all_movement.xlsx')
            .then((response) => response.arrayBuffer())
            .then((buffer) => {
              return {
                result,
                buffer,
              };
            });
        }
      )
      .then(({ result, buffer }): void => {
        const template = new XlsxTemplate(buffer);
        let templateData = {
          data: result.data,
          detailsData: result.detailsData,
        };
        // template-xlsx don't support table headers of type headers.column... So map to headers_column
        templateData = (Object.keys(result.headers) as string[]).reduce(
          (acc, key) => {
            acc['headers_' + key] = result.headers[key];
            return acc;
          },
          templateData
        );
        template.substitute('Export', templateData);
        template.substitute('Export détaillé', templateData);

        // Update files for pivot table
        for (const file of [
          'xl/pivotCache/pivotCacheDefinition1.xml',
          'xl/pivotCache/pivotCacheRecords1.xml',
          'xl/pivotTables/pivotTable1.xml',
        ]) {
          let content = template.archive.file(file).asText();
          // D'ont use builtin extractPlaceholders because of bad regex with character " (xml files)
          extractPlaceholders(content).forEach((placeholder) => {
            const substitution = templateData[placeholder.name];
            content = content.replace(
              placeholder.placeholder,
              template.stringify(substitution)
            );
          });
          template.archive.file(file, content);
        }

        const xlsx = template.generate({ type: 'arraybuffer' });

        // Download generated xlsx file
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([xlsx]));
        const filename = `all-movements-${today
          .toLocaleDateString()
          .replace('/', '_')}-${today.toLocaleTimeString()}.xlsx`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error('Error building xls file', err);
      });
  };

  return (
    <div>
      <SzButton 
        className="my-auto" 
        variant="secondary" 
        onClick={handleClick} 
        isDisabled={isLoading}
        loader={isLoading} >
        <span className="d-inline-flex align-items-center m-auto px-0 py-1 px-md-2">
          <SzIcon variant="line" icon="common-file-text-download" />
          <span className="pl-2">{translate('charts.export-all')}</span>
        </span>
      </SzButton>
    </div>
  );
};
